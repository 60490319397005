class PageContext {
  public static getContext(): PageContextType {
    const pathnameArr = window.location.pathname.split('/')
    if (/.+\..+/.test(pathnameArr[pathnameArr.length - 1] as string)) {
      pathnameArr.pop()
    }

    const { theme } = this.getUrlQuery()

    if (pathnameArr.length < 3) {
      return typeof theme !== 'undefined'
        ? {
            theme
          }
        : {}
    }

    const context: PageContextType = {}

    typeof pathnameArr[1] !== 'undefined' && (context.lang = pathnameArr[1])
    typeof pathnameArr[2] !== 'undefined' && (context.channel = pathnameArr[2])
    typeof pathnameArr[3] !== 'undefined' && (context.client = pathnameArr[3])
    typeof pathnameArr[4] !== 'undefined' && (context.action = pathnameArr[4])
    typeof pathnameArr[5] !== 'undefined' && (context.id = pathnameArr[5])
    typeof theme !== 'undefined' && (context.theme = theme)

    return context
  }

  public static getContextByName(name: string): string | null {
    const context = this.getContext()

    return context ? context[name] || null : null
  }

  private static getUrlQuery(): Record<string, string> {
    let query = window.location.href.split('?')[1]
    const result: Record<string, string> = {}

    if (query) {
      if (query.indexOf('#') > -1) {
        query = query.split(/\/?#/)[0] as string
      }

      const queryArr = query.split('&')
      queryArr.forEach(param => {
        const pair = param.split('=')
        result[pair[0] as string] = pair[1] as string
      })
    }

    return result
  }
}

// 路由规则 [protocal]:/[hostname]/[lang]/[channel]/[client]/[action]/[id]?param1=value1&param2=value2
interface PageContextType extends Record<string, string> {
  lang?: string
  channel?: string
  client?: string
  action?: string
  id?: string
  theme?: string
}

export default PageContext
