import PageContextType from './PageContext'

class AppConfigClazz {
  // 最终可用的app配置数据
  public static data: AppConfigNS.AppConfigData

  // 所有应用配置
  // @ts-ignore
  private static CONFIG: Record<string, AppConfigNS.AppConfigData> = __CONFIG__

  // 默认应用编码
  private static FALLBACK_APP_CODE = 'tga'

  /**
   * 生成App配置数据
   * @param options 页面的路由模式
   */
  public static create(options: AppConfigNS.CreateOptions = {}) {
    const { routerMode = 'browser', getDataProvider } = options

    const data = routerMode === 'browser' ? this.getDataFromBrowserUrl() : this.getDataFromHashUrl()

    // 从provider中获取，并覆盖
    if (typeof getDataProvider === 'function') {
      const provider = getDataProvider()

      provider.appCode &&
        (data.appCode = typeof provider.appCode === 'function' ? provider.appCode() : provider.appCode)
      provider.lang && (data.lang = typeof provider.lang === 'function' ? provider.lang() : provider.lang)
      provider.theme && (data.theme = typeof provider.theme === 'function' ? provider.theme() : provider.theme)
    }

    // 从url中获取
    if (!data.appCode) {
      data.appCode = this.getAppCodeFromHost()
    }

    // 默认
    if (!data.appCode) {
      data.appCode = this.FALLBACK_APP_CODE
    }

    data.appCode = window.decodeURI(data.appCode).toLocaleLowerCase().replace('gnç', 'gnc')

    const appConfig = (this.CONFIG[data.appCode] || this.CONFIG[this.FALLBACK_APP_CODE]) as AppConfigNS.AppConfigData

    // 修正数据
    data.lang && (appConfig.lang = data.lang)
    data.theme && (appConfig.theme = data.theme)
    appConfig.appCode = appConfig.appCode.toLocaleLowerCase()

    this.data = appConfig
  }

  /**
   * browser模式获取应用编码
   * @returns 应用编码
   */
  private static getDataFromBrowserUrl(): DataFromUrl {
    const context = PageContextType.getContext()

    const res: DataFromUrl = {}

    typeof context.lang !== 'undefined' && (res.lang = context.lang)
    typeof context.channel !== 'undefined' && (res.appCode = context.channel)
    typeof context.theme !== 'undefined' && (res.theme = context.theme)

    return res
  }

  /**
   * 暂不实现
   * hash模式获取应用编码
   * @returns 应用编码
   */
  private static getDataFromHashUrl(): DataFromUrl {
    return {}
  }

  /**
   * 从Host中获取应用编码
   * @returns 应用编码
   */
  private static getAppCodeFromHost(): string {
    const appCode = window.location.hostname.split('.')[0] as string

    if (appCode === 'localhost' || appCode === '127') {
      return ''
    }

    return appCode
  }
}

interface DataFromUrl {
  lang?: string
  appCode?: string
  theme?: string
}

// 类型
const AppConfig: AppConfigNS.AppConfig = AppConfigClazz

export default AppConfig
